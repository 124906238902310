import { Button, Card, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AMAM from '../../images/AMAM-Logo.png';
import EMRAM from '../../images/EMRAM-Logo.png';
import INFRAM from '../../images/INFRAM-Logo.png';
import OEMRAM from '../../images/O-EMRAM-Logo.png';
import CISOM from '../../images/CISOM-Logo.png';
import CCMM from '../../images/CCMM-Logo.png';
import CCOMM from '../../images/C-COMM-Logo.png';
import DIAM from '../../images/DIAM-Logo.png';
import DIGITALRADAR from '../../images/digitalradar_logo.svg';
import DHI from '../../images/DHI_Logo_Long.png';
import { getSurveyType } from '@survey/common/dist/actions/surveys.actions';
import { getResource } from '@survey/common/dist/utilities/getResource';

const getScoringLogo = (type) => {
  switch (type) {
    case 'EMRAM 2022':
      return EMRAM

    case 'EMRAM':
      return EMRAM

    case 'O-EMRAM':
      return OEMRAM;

    case 'AMAM':
      return AMAM;

    case 'INFRAM':
      return INFRAM;

    case 'INFRAM 2024':
      return INFRAM;

    case 'DigitalRadar':
      return DIGITALRADAR;

    case 'CISOM':
      return CISOM;

    case 'CCMM':
      return CCMM;

    case 'C-COMM':
      return CCOMM;

    case 'DIAM':
      return DIAM;

    case 'DHI':
      return DHI

    case 'DHIShort':
      return DHI

    case 'DHI for CCMM':
      return DHI;

    case 'GDHP':
      return DHI;

    case 'NHDI':
      return DHI;

    default:
      
  }
};

class SurveyCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      surveyType: {},
    };

    this.goToSurvey = this.goToSurvey.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const newState = { ...state };

    if (!isEmpty(props.surveyType) && props.survey.surveyTypeID === props.surveyType.surveyTypeID) {
      newState.surveyType = props.surveyType;
    }

    return newState;
  }

  goToSurvey() {
    const { history, survey } = this.props;

    history.push(`surveyLanding/${survey.surveyGuid}/home`);
  }

  getStatusText() {
    const { resources, browserLanguage } = this.props;
    switch (this.props.survey.status) {
      case 'Not Started':
        return getResource(resources, browserLanguage, 'Message', 'New');

      case 'In Progress':
        return getResource(resources, browserLanguage, 'Message', 'In Progress');

      case 'In Review':
        return getResource(resources, browserLanguage, 'Message', 'In Review');

      case 'Submitted to HA':
        return getResource(resources, browserLanguage, 'Message', 'Submitted');

      case 'Completed':
        return getResource(resources, browserLanguage, 'Label', 'Completed');

      default:
        return getResource(resources, browserLanguage, 'Message', 'NEW!');
    }
  }

  render() {
    const { browserLanguage, classes, resources, survey } = this.props;
    const { surveyType } = this.state;

    return (
      <Card className={classes.card}>
        <div className={classes.infoContainer}>
          <Typography variant="subtitle2" color="textSecondary">
            {this.getStatusText()}
          </Typography>
          <Typography style={{ flexGrow: 1 }} variant="h5" gutterBottom>
            {survey.surveyName}
          </Typography>
        </div>
        <div className={classes.actionContainer}>
          {!isEmpty(surveyType.scoringType) && surveyType.scoringType !== 'None' && <img className={classes.emramLogo} src={getScoringLogo(surveyType.scoringType)} alt="emram opaque" />}
          <Button onClick={this.goToSurvey} style={{ fontSize: '1rem', padding: '.25rem 1rem' }} variant="contained" color="primary">
            {getResource(resources, browserLanguage, 'Label', 'GO')}
          </Button>
        </div>
      </Card>
    );
  }
}

const styles = ({ breakpoints }) => ({
  card: {
    alignItems: 'center',
    margin: '1rem',
    padding: '1rem 2rem',
    [breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  cardHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '4rem',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  emramLogo: {
    marginRight: '2.5rem',
    height: '3.5rem',
  },
});

SurveyCard.propTypes = {
  survey: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.surveys.get('isLoading'),
    surveyType: props.survey.surveyType,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getSurveyType,
  })(withRouter(SurveyCard))
);
