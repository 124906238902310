"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scoringTypeToTechID = exports.otherServices = exports.numberToLetter = exports.checkParentQuestionData = exports.calculatePageStatus = exports.SurveyPageStatuses = void 0;
var _pages = require("../actions/pages.actions");
var _filterQuestions = require("./filterQuestions");
var _lodash = require("lodash");
var _this = void 0;
var SurveyPageStatuses = exports.SurveyPageStatuses = {
  NOT_STARTED: 'Not Started',
  INCOMPLETE: 'Incomplete',
  COMPLETE: 'Complete'
};
var numberToLetter = exports.numberToLetter = function numberToLetter(i) {
  return (i >= 26 ? _this.toLetter((i / 26 >> 0) - 1) : '') + 'abcdefghijklmnopqrstuvwxyz'[i % 26 >> 0];
};
//207   Other
//1385	Other 2
//1386	Other 3
//1387	Other 4
//1388	Other 5
var otherServices = exports.otherServices = ['207', '1385', '1386', '1387', '1388'];
var scoringTypeToTechID = exports.scoringTypeToTechID = function scoringTypeToTechID(scoringType) {
  //275	HIMSS Certified Organization - AMAM
  //276	HIMSS Certified Organization - CISOM
  //277	HIMSS Certified Organization - EMRAM
  //278	HIMSS Certified Organization - O-EMRAM
  //279	HIMSS Certified Organization - INFRAM
  //280	HIMSS Certified Organization - DIAM
  //281	HIMSS Certified Organization - CCMM
  var scoringTypeToTechID = {};
  scoringTypeToTechID['AMAM'] = 275;
  scoringTypeToTechID['CISOM'] = 276;
  scoringTypeToTechID['EMRAM'] = 277;
  scoringTypeToTechID['O-EMRAM'] = 278;
  scoringTypeToTechID['INFRAM'] = 279;
  scoringTypeToTechID['DIAM'] = 280;
  scoringTypeToTechID['CCMM'] = 281;
  scoringTypeToTechID['EMRAM 2022'] = 277;
  scoringTypeToTechID['C-COMM'] = 1402;
  scoringTypeToTechID['DHI'] = 1403;
  scoringTypeToTechID['INFRAM 2024'] = 279;
  return scoringTypeToTechID[scoringType];
};
var _checkParentQuestionData = exports.checkParentQuestionData = function checkParentQuestionData(parentQuestionID, answerIDs, questionData, parentQuestionInfo) {
  var exists = false;
  Object.keys(questionData).forEach(function (k) {
    if (k === parentQuestionID && exists === false) {
      questionData[parentQuestionID].answer.forEach(function (a) {
        var answer = a;
        if (parentQuestionInfo) {
          if (parentQuestionInfo.controlType === 'CustomDiamServiceCheckbox') {
            var svc = JSON.parse(a);
            answer = svc.serviceID;
          } else if (parentQuestionInfo.controlType === 'TechnologyInstallation' || parentQuestionInfo.controlType === 'DiamTechnologyInstallation' || parentQuestionInfo.controlType === 'DiamTechnologyInstallation_NoIntegrationLevel' || parentQuestionInfo.controlType === 'DigitalRadarTechnologyInstallation' || parentQuestionInfo.controlType === 'DigitalRadarTechnologyInstallation_NoIntegrationLevel') {
            if (a.length > 0) {
              var arr = JSON.parse(a);
              answer = arr[0];
            }
          } else if (parentQuestionInfo.controlType === 'Address') {
            var address = JSON.parse(a);
            answer = address.countryID;
          }
        }
        answerIDs.forEach(function (a1) {
          if (!exists) exists = answer == a1;
        });
      });
    }
    if (exists === false && questionData[k].questions.length > 0) {
      exists = _checkParentQuestionData(parentQuestionID, answerIDs, questionData[k].questions, parentQuestionInfo);
    }
  });
  return exists;
};

/*
 * Define a default status for the page.
 * Iterate through all of the questions to use on this survey and determine from the tab
 * and page that the question appears on if that question is on the page passed in to
 * this method.
 *
 * If that question is on this page, increase the count of total questions on this page
 * and then determine if that question has been answered previously, or currently to determine
 * how many questions are remaining to be answered
 */

var calculatePageStatus = exports.calculatePageStatus = function calculatePageStatus(pageID, entity, tabs, surveyType, survey, technologies) {
  var techQuestionsForm = typeof pageID === 'string' && pageID.startsWith('tq_');
  var serviceList = [];
  var status = SurveyPageStatuses.COMPLETE;
  var totalQuestions = 0;
  var totalPageQuestions = 0; //excluding branching question for Question numbering
  var totalQuestionsAnswered = 0;
  var tabQuestionCount = {};
  var hsOnlySurvey = survey.entities.length === 1 && [6, 8].includes(survey.entities[0].haEntityTypeID);
  var data = entity.data.questions;
  if (techQuestionsForm) data = entity.data.technologyQuestions;
  var _ProcessQuestionCount = function ProcessQuestionCount(question, questionIDs, tabID) {
    var isBranchingQuestion = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    if ((0, _filterQuestions.filterEntityQuestion)(entity, surveyType, question, survey.questions)) {
      var haEntityTypes = [];
      question.entityTypes.forEach(function (qe) {
        var entityType = survey.entityTypes.find(function (e) {
          return e.entityTypeId === Number(qe);
        });
        if (entityType) {
          haEntityTypes.push.apply(haEntityTypes, entityType.haEntityTypes);
        }
      });
      if (hsOnlySurvey && question.entityTypes.includes(1) || haEntityTypes.length === 0 || haEntityTypes.includes(entity.haEntityTypeID)) {
        var branchingQuestions = getBranchingQuestions(question, questionIDs);
        if (isBranchingQuestion === false) {
          totalPageQuestions++;
          if (tabQuestionCount[tabID] == null) {
            tabQuestionCount[tabID] = {
              techQuestionsForm: techQuestionsForm,
              count: 1
            };
          } else {
            tabQuestionCount[tabID].count += 1;
          }
        }
        if (branchingQuestions.length > 0) {
          branchingQuestions.forEach(function (q) {
            return _ProcessQuestionCount(q, [].concat(questionIDs, [q.questionID]), tabID, true);
          });
        }
        //}
      }
    }
  };
  var getAnswer = function getAnswer(data, questionIDs) {
    var qIDs = (0, _lodash.cloneDeep)(questionIDs);
    if ((0, _lodash.isEmpty)(data)) {
      return '';
    }
    var questionData = data;
    /* For tech questions, the first questionIDs element is the technologyID so update the array and the questionData object to reflect that. */
    if (techQuestionsForm) {
      var techID = qIDs.shift();
      questionData = data[techID];
    }
    // Dig down into the data object to get the question data.
    for (var i = 0; i < qIDs.length; i++) {
      /* If questionData is null, props are probably in an unstable state so
            return an empty string and wait for them to stablize. */
      if (!questionData) {
        return '';
      }
      // If this is the last element, don't apply the questions suffix.
      if (qIDs.length === 1) {
        questionData = questionData[qIDs[i]];
      } else if (i === qIDs.length - 1) {
        questionData = questionData[qIDs[i]];
      } else {
        //We can't guarentee the questionData will exist in prevData so skipping if it doesn't
        if (questionData[qIDs[i]]) {
          questionData = questionData[qIDs[i]].questions;
        }
      }
    }
    if (!questionData) {
      return '';
    } else {
      return questionData.answer;
    }
  };
  var _getSelectedServices = function getSelectedServices(questionData, questionID) {
    var answer = [];
    Object.keys(questionData).forEach(function (k) {
      if (k == questionID) {
        answer = questionData[questionID].answer;
      }
      if (questionData[k].questions.length > 0) {
        answer = _getSelectedServices(questionData[k].questions, questionID);
      }
    });
    return answer;
  };
  var getBranchingQuestions = function getBranchingQuestions(question, questionIDs) {
    var branchingQuestions = [];
    var answers = getAnswer(data, questionIDs);
    totalQuestions++;
    if (!answers) {
      return branchingQuestions;
    }
    if (answers.length > 0) {
      totalQuestionsAnswered++;
    }
    /* If there are no branching questions, return an empty array */
    if ((0, _lodash.isEmpty)(question.branchingQuestions)) return branchingQuestions;

    /* If the answer is a string, convert it to an array with that single element so it can be processed */
    var isString = typeof answers === 'string';
    var answersList = answers;
    if (isString) {
      answersList = [answers];
    }

    /* If the question is a tech installation the branching question is just the first string in each row */
    if (question.controlType === 'TechnologyInstallation' || question.controlType === 'DiamTechnologyInstallation' || question.controlType === 'DiamTechnologyInstallation_NoIntegrationLevel' || question.controlType === 'DigitalRadarTechnologyInstallation' || question.controlType === 'DigitalRadarTechnologyInstallation_NoIntegrationLevel') {
      answersList = answersList.map(function (a) {
        var arr = JSON.parse(a);
        return arr[0];
      });
      answersList = (0, _lodash.uniq)(answersList);
    }
    if (question.controlType === 'CustomDiamServiceCheckbox') {
      answersList = answersList.map(function (a) {
        var svc = JSON.parse(a);
        return svc.serviceID;
      });
      answersList = (0, _lodash.uniq)(answersList);
    }
    if (question.controlType === 'CustomCertifiedOrg') {
      answersList = answersList.map(function (a) {
        var arr = JSON.parse(a);
        return arr[0];
      });
      answersList = (0, _lodash.uniq)(answersList);
    }
    if (question.controlType === 'DiamQuestion') {
      answersList = answersList.map(function (v, index) {
        var a = JSON.parse(v);
        return a.rowValue;
      });
    }
    /* For each answer found, find all branching questions for that answer. */
    answersList.forEach(function (answerID) {
      /* If the answerObject is undefined or an empty string, skip this answer */
      if (!answerID) return;
      /* If the question has branching questions for this answer, return a list of them. */
      if (answerID && question.branchingQuestions[answerID]) {
        /* Get an array of questionIDs to display for this answer */
        var newQuestionIds = question.branchingQuestions[answerID];
        /* Get the full question objects for all branching questions */
        branchingQuestions.push.apply(branchingQuestions, newQuestionIds.reduce(function (all, qId) {
          var branchingQuestion = (0, _lodash.find)(allQuestions, {
            questionID: Number(qId)
          });
          if (branchingQuestion) {
            var showChild = true;
            if (branchingQuestion.parentQuestions && Object.keys(branchingQuestion.parentQuestions).length > 0) {
              var questionData = entity.data.questions;
              var addressQuestion = survey.questions.find(function (q) {
                return q.mappingGroupAttribute === '-5';
              });
              showChild = Object.keys(branchingQuestion.parentQuestions).every(function (k) {
                if (Number(k) === 805 && addressQuestion) {
                  //if parent question is Country then look for Address question

                  var result = _checkParentQuestionData(addressQuestion.questionID.toString(), branchingQuestion.parentQuestions[k], questionData, addressQuestion);
                  return result;
                } else {
                  var parentQuestionInfo = survey.questions.find(function (q) {
                    return q.questionID === Number(k);
                  });
                  var _result = _checkParentQuestionData(k, branchingQuestion.parentQuestions[k], questionData, parentQuestionInfo);
                  return _result;
                }
              });
            }
            if (showChild && branchingQuestion.controlType !== 'Description') {
              all.push(branchingQuestion);
            }
          }
          return all;
        }, []));
      }
    });
    return branchingQuestions;
  };
  var surveyTypeQuestions = surveyType.questions;
  var allQuestions = survey.questions;
  //Which imaging services do you want to assess? 1935
  var serviceQuestion = allQuestions.find(function (q) {
    return q.mappingTo === 'HA Database' && q.mappingGroupAttribute === '-3';
  });
  if (serviceQuestion) {
    var services = _getSelectedServices(entity.data.questions, serviceQuestion.questionID);
    serviceList = services.map(function (s) {
      var serviceAnswer = JSON.parse(s);
      return serviceAnswer.serviceID;
    });
  }
  if (techQuestionsForm) {
    surveyTypeQuestions = surveyType.technologyQuestions;
    allQuestions = survey.technologyQuestions;
  }
  var curTabs = tabs.reduce(function (all, t) {
    if (t.pageId.toString() === pageID) all.push(t);
    return all;
  }, []);
  var getQuestionsFromSurveyType = function getQuestionsFromSurveyType(all, curr) {
    if ((0, _lodash.findIndex)(all, {
      questionID: curr.questionID
    }) > -1) {
      return all;
    }
    var question = (0, _lodash.find)(allQuestions, {
      questionID: curr.questionID
    });
    if (question) {
      all.push(question);
    }
    return all;
  };
  var questions = surveyTypeQuestions.reduce(getQuestionsFromSurveyType, []);
  curTabs.forEach(function (t) {
    var tabQuestions = [];
    if (techQuestionsForm === true) {
      tabQuestions = questions.filter(function (q) {
        return q.technologies.includes(t.tabId);
      });
    } else {
      tabQuestions = questions.filter(function (q) {
        return q.tabID === t.tabId;
      });
    }
    tabQuestions.forEach(function (q) {
      var parentQuestionIDs = [];
      if (techQuestionsForm === true) {
        parentQuestionIDs = [t.tabId];
      }
      if (q.controlType !== 'Description' && q.controlType !== 'CustomLabel' && (q.controlType !== 'DiamQuestion' || serviceList.length > 0) && (q.controlType !== 'DiamFreeTextQuestion' || serviceList.length > 0) && (q.controlType !== 'DiamRespondentInfo' || serviceList.length > 0)) {
        _ProcessQuestionCount(q, [].concat(parentQuestionIDs, [q.questionID]), t.tabId);
      }
    });
  });
  if (totalQuestionsAnswered === 0) {
    status = SurveyPageStatuses.NOT_STARTED;
  } else if (totalQuestionsAnswered < totalQuestions) {
    status = SurveyPageStatuses.INCOMPLETE;
  }
  return {
    status: status,
    totalQuestions: totalQuestions,
    totalQuestionsAnswered: totalQuestionsAnswered,
    tabQuestionCount: tabQuestionCount,
    totalPageQuestions: totalPageQuestions
  };
};

//export const calculatePageStatus = (pageID, entity, tabs, surveyType, survey, technologies) => {
//  let status = SurveyPageStatuses.COMPLETE;
//  let totalQuestions = 0;
//  let totalQuestionsAnswered = 0;
//  let questionKeys = [];
//
//  if (typeof pageID === 'string' && pageID.startsWith('tq_')) {
//    const curTechTabs = getCurrentPageTabs(pageID, tabs, survey.questions, surveyType.questions, survey.technologyQuestions, surveyType.technologyQuestions);
//
//    const curTechQuestions = {};
//
//    curTechTabs.forEach((techTab) => {
//      // If the surveyType has changed adding in a other questions / technologies, an older survey won't have that data, so don't add it
//      if (!isEmpty(entity.data.technologyQuestions[techTab.tabId])) {
//        curTechQuestions[techTab.tabId] = entity.data.technologyQuestions[techTab.tabId];
//      }
//    });
//
//    const techBranchingQuestions = getTechBranchingQuestions(curTechQuestions, survey, entity.data.questions, pageID);
//    totalQuestions = techBranchingQuestions.totalQuestions;
//    totalQuestionsAnswered = techBranchingQuestions.totalQuestionsAnswered;
//  } else {
//    const tabIDs = getCurrentPageTabs(pageID, tabs, survey.questions, surveyType.questions, survey.technologyQuestions, surveyType.technologyQuestions, technologies).map((t) => t.tabId);
//
//    // Filter all questions down to questions on this page
//    const questionsOnPage = surveyType.questions.filter((q) => {
//      const question = survey.questions.find((ques) => ques.questionID === q.questionID);
//      if (question !== undefined) {
//        if (question.controlType !== 'Description') {
//          //do not include questions with Control Type 'Description' in the total question count
//
//          //SUPER HACKY FIX COMIN AT YA! For Org and Contact Detail page on the Parent entity, make sure the question has entity type 6 or 8 (parent entity types)
//          //Tried to fix this so that we could filter question on entityType by adding the other Health System Type ID to questions (8), but in Survey Builder there is only an option for "Health System"
//          //Why do surveys have 6 and 8 as parent types but questions only have 6???
//          if (pageID === 214 || pageID === '214' && entity.parentEntityID === null && (!question.entityTypes.includes(6) && !question.entityTypes.includes(8))) {
//            return false;
//          } else {
//
//            let questionVisible = true;
//            if (question.parentQuestions && Object.keys(question.parentQuestions).length > 0) {
//              questionVisible = Object.keys(question.parentQuestions).every((k) => {
//                const result = checkParentQuestionData(k, question.parentQuestions[k], entity.data.questions);
//                return result;
//              });
//            }
//            return questionVisible && tabIDs.includes(question.tabID);
//
//          }
//        }
//      }
//
//      return false;
//    });
//
//   // if (pageID === 214 || pageID === '214' && entity.parentEntityID === null) {
//   //   console.log('questionsOnPageis: ', questionsOnPage);
//   // }
//
//    //in here its getting 17 questions for the parent:
//    questionsOnPage.forEach((question) => {
//      const entityQuestion = entity.data.questions[question.questionID];
//
//      // Get answered branching questions data
//      if (entityQuestion && entityQuestion.answer !== null) {
//
//        const results = getAnsweredBranchingQuestions(entityQuestion, question.questionID, survey, entity.data.questions, pageID, 1);
//
//        totalQuestions += results.totalQuestions;
//        totalQuestionsAnswered += results.totalQuestionsAnswered;
//      } else {
//        questionKeys.push(question.questionID);
//      }
//
//      // Get all unanswered questions
//      const questions = survey.questions.filter((q) => questionKeys.includes(q.questionID.toString()));
//
//      totalQuestions += getAllBranchingQuestions(questions);
//    });
//  }
//
//  if (totalQuestionsAnswered === 0) {
//    status = SurveyPageStatuses.NOT_STARTED;
//  } else if (totalQuestionsAnswered < totalQuestions) {
//    status = SurveyPageStatuses.INCOMPLETE;
//  }
//
//  return {
//    status,
//    totalQuestions,
//    totalQuestionsAnswered,
//  };
//};