import { AppBar, Button, Drawer, Grid, Hidden, LinearProgress, Toolbar, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import MenuIcon from '@mui/icons-material/Menu';
import classNames from 'classnames';
import { find, findIndex, isEmpty, cloneDeep, filter, some } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import Logo from '../../../images/HIMSSlogo_Hfullcolor_RGB_whitetext.png';
import DRLogo from '../../../images/digitalradar_logo.png';
import Gear from '../../../images/gear.png';
import GearDR from '../../../images/gearDR.png';
import LeftPanel from '../../../images/Left-Panel.png';
import LeftPanelDR from '../../../images/Left-PanelDR_new.png';
import '../../layout/styles/mobile.css';
import { hasPermission } from '../../auth/authOperations';
import { CREATE_DELEGATES } from '../../auth/permissions';
import ScoringCard from '@survey/common/dist/components/widgets/scoring.component';
import Widget from '../../widgets/widget.component';
import Entity from './entity.component';
import SurveyLandingHome from './surveyLandingHome.container';
import SurveyPage from './surveyPage.container';
import LoadingOverlay from '../../layout/loadingOverlay.component';
import { getPages } from '@survey/common/dist/actions/pages.actions';
import { getProductsList } from '@survey/common/dist/actions/products.actions';
import { EventBus } from '@survey/common/dist/utilities/EventBus';
import { getEntityTypes } from '@survey/common/dist/actions/entities.actions';
import { getOrganizationTypes } from '@survey/common/dist/actions/organizations.actions';
import { getSurvey, saveSurvey, saveSurveyChanges, getUserSurveys } from '@survey/common/dist/actions/surveys.actions';
import { submitSurvey, SUBMIT_SURVEY_SUCCESS, downLoadSurveyPDF, downLoadSurveyType } from '@survey/common/dist/actions/surveys.actions';
import { getResources } from '@survey/common/dist/actions/resources.actions';
import { getTabs } from '@survey/common/dist/actions/tabs.actions';
import { getTechnologiesList, getTechnologies } from '@survey/common/dist/actions/technologies.actions';
import { getVendorsList } from '@survey/common/dist/actions/vendors.actions';
import { getCountriesList, getRegionsList, getRegions } from '@survey/common/dist/actions/countries.actions';
import { getDelegates, getUserInfo } from '@survey/common/dist/actions/delegates.actions';
import { createSurveyState } from '@survey/common/dist/utilities/createSurveyState';
import { calculatePageStatus } from '@survey/common/dist/utilities/surveyUtils';
import { getResource } from '@survey/common/dist/utilities/getResource';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import LogOff from '@survey/common/dist/components/dialogs/Logoff';
import { handleToastMessage } from '../../layout/layout.actions';
import { getSettings } from '@survey/common/dist/actions/settings.actions';
import Settings from '@survey/common/dist/components/dialogs/Settings';
import { getMaintenance } from '@survey/common/dist/actions/maintenance.actions';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CookieConsent from '@survey/common/dist/components/dialogs/CookieConsent';
import AuthTimeout from '@survey/common/dist/components/dialogs/AuthTimeout';
import Auth from '../../auth/auth';
import detectIncognito from '@survey/common/dist/utilities/detectIncognito';
import Acknowledge from '@survey/common/dist/components/dialogs/Acknowledge';

class SurveyLandingContainer extends Component {
  constructor(props) {
    super(props);

    this.auth = new Auth(this.props.history, props.setAuth, props.clearAuth);

    let touVariable = true;
    let germanTenderCookieVariable = true;

    if (localStorage.getItem('tou_hidden')) {
      touVariable = false;
      germanTenderCookieVariable = false;
    }

    let privateWindowAcknowledgedVariable = false;

    if (sessionStorage.getItem('private_window_acknowledged')) {
      privateWindowAcknowledgedVariable = true;
    }

    this.state = {
      entity: {},
      pages: [],
      questions: [],
      surveyType: {},
      surveyTypeRequested: false,
      techName: '',
      technologyQuestions: [],
      showSubmitDialog: false,
      openChildDialog: false,
      touOpen: touVariable,
      germanTenderCookieOpen: germanTenderCookieVariable,
      forceSaveSurvey: false,
      surveyText: 'Save Survey',
      currentlySavingSurvey: false,
      activeTab: 0,
      surveyLanguage: '',
      newSurveyLanguage: '',
      delegatesResponse: {},
      showActivationDialog: false,
      zoomLevel: 100,
      exportOption: 1,
      newLang: '',
      downloading: false,
      maintenanceResponse: {},
      maintenanceEnabled: false,
      requiredFieldError: false,
      productsList: [],
      entitiesToExport: [],
      showLogOutDialog: false,
      showTimeoutDialog: false,
      userTokenTimeout: false,
      isPrivateBrowser: false,
      showPrivateWindowDetected: false,
      privateWindowAcknowledged: privateWindowAcknowledgedVariable,
    };

    [
      'getEntityName',
      'handleDrawerToggle',
      'updateDrawer',
      'onSubmit',
      'handleDialogClose',
      'getEntityDelegatesCount',
      'forceSaveSurvey',
      'surveyIsLoaded',
      'onChangeTab',
      'handleSelectChange',
      'saveSurvey',
      'newGetDelegates',
      'newGetSurvey',
      'handleCloseActivation',
      'handleOpenActivation',
      'handleUpdateSettings',
      'updateZoomLevel',
      'updateLanguage',
      'setZoomLevel',
      'setLanguage',
      'setExportOption',
      'updateExportOption',
      'getMaintenanceEnabled',
      'setMaintenanceEnabled',
      'getMaintenanceBanner',
      'handleCloseMaintenance',
      'logOff',
      'confirmLogOff',
      'updateEntitiesToExport',
      'forceSaveSurveyAndRefreshToken',
      'setAuthTimer',
      'renewSessionAndResetTimers',
      'checkSurveyName',
    ].forEach((k) => (this[k] = this[k].bind(this)));
  }

  componentDidMount() {
    const {
      pages,
      tabs,
      survey,
      delegates,
      entityTypes,
      technologies,
      technologiesList,
      productsList,
      vendorsList,
      organizationTypes,
      countriesList,
      regionsList,
      surveys,
      regions,
      resources,
      getUserInfo,
      privateWindowAcknowledged,
    } = this.props;
    (isEmpty(survey) || survey.surveyGuid !== this.props.match.params.id) && this.props.getSurvey(this.props.match.params.id);
    (isEmpty(delegates) || survey.surveyGuid !== this.props.match.params.id) && this.props.getDelegates(this.props.match.params.id);
    isEmpty(pages) && this.props.getPages();
    isEmpty(resources) && this.props.getResources();
    isEmpty(tabs) && this.props.getTabs();
    isEmpty(entityTypes) && this.props.getEntityTypes();
    isEmpty(organizationTypes) && this.props.getOrganizationTypes();
    getUserInfo();
    

    isEmpty(vendorsList) && this.props.getVendorsList();
    // forcing the products to load here because survey may not be defined here yet and we won't have products list when we need it
    isEmpty(productsList) && this.props.getProductsList();

    isEmpty(technologiesList) && this.props.getTechnologiesList();
    isEmpty(technologies) && this.props.getTechnologies();
    isEmpty(countriesList) && this.props.getCountriesList();
    isEmpty(regionsList) && this.props.getRegionsList();
    isEmpty(regions) && this.props.getRegions();
    isEmpty(survey) && isEmpty(surveys) && this.props.getUserSurveys();

    this.updateDrawer();
    window.addEventListener('resize', this.updateDrawer);

    this.props.getSettings().then((response) => {
      this.setState({ maintenance: response.response });
    });

    this.newGetSurvey(survey, this.props.match.params.id);

    this.getMaintenanceEnabled();
    this.setAuthTimer();
  }

  static getDerivedStateFromProps(props, state) {
    const newState = { ...state };
    const { surveyType, survey, surveys, history, match, permissions, productsList } = props;
    newState['productsList'] = productsList;

    if (!hasPermission(CREATE_DELEGATES, permissions) && !isEmpty(surveys) && !isEmpty(match.params.id)) {
      const surveyGuid = match.params.id;

      findIndex(surveys, { surveyGuid }) === -1 && history.push('/home');
    }

    if (!isEmpty(survey) && isEmpty(surveyType) && !newState.surveyTypeRequested) {
      newState.surveyTypeRequested = true;

      props.getSurveyType(survey.surveyTypeID);
    }

    const paths = props.history.location.pathname.split('/');

    if (paths[4] !== undefined && !isEmpty(survey)) {
      const entity = find(props.survey.entities, { entityID: Number(paths[4]) });

      if (entity.parentEntityID !== null && entity.parentEntityID !== state.parentEntityID) {
        newState.parentEntityID = entity.parentEntityID;
      }
    }

    return newState;
  }

  async getMaintenanceEnabled() {
    const response = await this.props.getMaintenance();
    this.setMaintenanceEnabled(response);
  }

  setMaintenanceEnabled(response) {
    console.log('setMaintenanceEnabled response is: ', response);
    let maintenanceHiddenCookieVal = sessionStorage.getItem('maintenance_hidden');
    if (maintenanceHiddenCookieVal) {
      //if we have the maintenance_hidden local storage item, then dont show the maintenance banner
      this.setState({ maintenanceEnabled: false });
    } else {
      if (response.response) {
        this.setState({ maintenanceEnabled: response.response[0]['maintenanceActiveFlag'] === 0 ? false : true });
      }
    }
    if (response.response) {
      this.setState({ maintenanceResponse: response.response[0] });
    }
  }

  getMaintenanceBanner(maintenanceResponse) {
    const { surveyType, survey } = this.props;

    return maintenanceResponse[`maintenanceText${survey.language}`] ? maintenanceResponse[`maintenanceText${survey.language}`] : maintenanceResponse['maintenanceTextEnglish'];
  }

  handleCloseMaintenance() {
    sessionStorage.setItem('maintenance_hidden', true);
    this.setState({ maintenanceEnabled: false });
  }

  updateZoomLevel(zoom) {
    this.setState({ zoomLevel: zoom });
  }

  setZoomLevel(zoom) {
    if (zoom === 100 || zoom === '' || zoom === undefined) {
      document.body.classList.remove('zoomed');
    } else {
      document.body.classList.add('zoomed');
    }
  }

  setExportOption(exportOption) {
    const { surveyType, survey } = this.props;
    //1 = blank survey
    //2 = full survey

    console.log('exportOption is: ', exportOption);

    const surveyLang = survey.language == null ? '' : survey.language;

    if (exportOption === 1) {
      this.setState({ downloading: true });
      this.props.downLoadSurveyPDF(survey.surveyID, surveyLang, 'full', this.state.entitiesToExport).then(() => {
        this.setState({ openDownloadDialog: false, downloading: false });
      });
    }

    if (exportOption === 2) {
      this.setState({ downloading: true });
      this.props.downLoadSurveyPDF(survey.surveyID, surveyLang, 'blank', '').then(() => {
        this.setState({ openDownloadDialog: false, downloading: false });
      });
    }

    //set this to zero, next time they open the dialog export will be set back to full
    this.setState({ exportOption: 1 });
  }

  updateExportOption(exportOption) {
    this.setState({ exportOption: exportOption });
  }

  updateEntitiesToExport(entities) {
    this.setState({ entitiesToExport: entities });
  }

  updateLanguage(lang) {
    //console.log('in SLC updateLanguage, lang is : ', lang);

    this.setState({ newLang: lang });
  }

  setLanguage(lang) {
    const { survey } = this.props;
    this.setState({ showActivationDialog: false });

    //console.log('survey.language: ', survey.language);
    //console.log('lang: ', lang);
    if (lang !== survey.language && lang !== '' && lang !== undefined) {
      let changes = { setLanguage: true, updatedLanguage: lang };
      this.props.saveSurveyChanges(survey.surveyGuid, null, changes);
    }
  }

  handleUpdateSettings() {
    const { survey } = this.props;

    this.saveSurvey(survey, 'language update');
  }

  handleCloseActivation() {
    this.setState({ showActivationDialog: false });
  }

  handleOpenActivation() {
    this.setState({ showActivationDialog: true });
  }

  onChangeTab(activeTab) {
    this.setState({ activeTab }, console.log('tab changed', this.state.activeTab));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDrawer);
  }

  DrawerContent() {
    const { classes, surveyType, resources, survey } = this.props;
    const isGermanTenderEnv = process.env.REACT_APP_ENVIRONMENT === 'germantender';
    
    const paths = this.props.history.location.pathname.split('/');

    var cwsEmailValue = 'customerservice@himssanalytics.org';
    if (process.env.REACT_APP_DOD) {
      cwsEmailValue = 'himss4dha@himssanalytics.org';
    } else if (isGermanTenderEnv) {
      cwsEmailValue = 'support@digitalradar-krankenhaus.de';
    }

    return (
      <div className={isGermanTenderEnv ? `${classes.panel} ${classes.drpanel}` : classes.panel}>
        {!this.state.open && !isGermanTenderEnv ? <div style={{ margin: '24px 0px 40px 0px' }}></div> : <div style={{ height: '4rem' }} />}
        {this.showScoreWidget() &&
          <ScoringCard scoringType={surveyType.scoringType} resources={resources} language={survey.language} />
        }
        {surveyType &&
          !isEmpty(surveyType.widgets) &&
          surveyType.name !== 'Hospital Stage 6' &&
          surveyType.widgets.map((w, i) => <Widget key={`widget-${i}`} resources={resources} language={survey.language} widget={w} />)}
        <div className={this.state.maintenanceEnabled === true ? classes.supportContainerPadded : classes.supportContainer}>
          {isGermanTenderEnv && (
            <Fragment>
              <div style={{ color: '#FFFFFF' }}>
                <strong>Support:</strong>
                <br />
                <br />
                E-Mail:{' '}
                <a style={{ color: '#FFFFFF', textDecoration: 'none' }}
                  href={'mailto:support@digitalradar-krankenhaus.de' +
                    '?subject=Anfrage Kundensupport DigitalRadar' +
                    '&body=Sehr geehrtes DigitalRadar-Team,' +
                    '%0D%0A%0D%0A[Ihr Anliegen]' +
                    '%0D%0A%0D%0A%0D%0A' +
                    '%0D%0A______________________________________________' +
                    '%0D%0AKennung [bitte nicht löschen]' +
                    '%0D%0AErhebungsname: ' + encodeURIComponent(survey.surveyName) +
                    '%0D%0ADigitalRadar-Id: ' +  encodeURIComponent(survey.entities && survey.entities.map(e => e.entityID).join(', ')) +
                    '%0D%0AErhebungs-Id: ' + survey.surveyID +
                    '%0D%0A%0D%0A'
                  }
                >
                  support@digitalradar-krankenhaus.de
                </a>
                <br />
                <br />
              </div>
              
              <Button
                variant="outlined"
                className={'mobiefaqbutton ' + classes.faqButton}
                style={survey.language !== 'English' ? { minWidth: '100%', fontSize: '.8rem' } : { minWidth: '11rem' }}
                href={'https://ha-static-content.s3.amazonaws.com/SurveyFAQs/digital-radar-FAQ.pdf'}
                target="_blank"
              >
                {getResource(resources, survey.language, 'Label', 'FAQ')}
              </Button>
            </Fragment>
          )}

          {!isGermanTenderEnv && (
            <Button
              variant="outlined"
              className={'mobiemailbutton ' + classes.emailButton}
              href={'mailto:' + cwsEmailValue + ';' + survey.assignedToEmail + '?subject=Support Request&body=Survey: ' + encodeURIComponent(survey.surveyName) + '%0D%0ASurvey Id: ' + survey.surveyID + '%0D%0AModel: ' + (survey.surveyType && survey.surveyType.name ? encodeURIComponent(survey.surveyType.name) : '') + '%0D%0A%0D%0A' }
            >
              {getResource(resources, survey.language, 'Label', 'EMAIL SUPPORT')}
            </Button>
          )}
        </div>
      </div>
    );
  }

  async handleSelectChange(event) {
    const { survey } = this.props;
    const { name, value } = event.target;

    this.setState({ [name]: value });
    survey.language = value === 'English' ? null : value;

    this.saveSurvey(survey, 'language update');
  }

  showScoreWidget() {
    const { surveyType } = this.props;
    //for Outpatient 6 and 7 dont display the score widget, but keep scoringType in data so that these are scored on submit (just o real time scoring)
    return (
      surveyType &&
      !isEmpty(surveyType.scoringType) &&
      surveyType.scoringType !== 'None' &&
      surveyType.name !== 'Outpatient Stage 7' &&
      surveyType.surveyTypeID !== 12 &&
      surveyType.surveyTypeID != 72 &&
      this.isEntityPage()
    );
  }

  getPageName() {
    const { techName } = this.state;
    const { survey, resources } = this.props;
    const paths = this.props.history.location.pathname.split('/');
    const page = find(this.props.pages, (page) => page.pageId === Number(paths[6]));

    return !isEmpty(page) && !techName ? getResource(resources, survey.language, 'Page', page.pageName) : techName;
  }

  getEntityName() {
    const paths = this.props.history.location.pathname.split('/');
    const entity = find(this.props.survey.entities, (entity) => entity.entityID === Number(paths[4]));

    return !isEmpty(entity) && entity.entityName !== null && entity.entityName;
  }

  getEntityDelegatesCount(delegates) {
    const paths = this.props.history.location.pathname.split('/');
    const entity = find(this.props.survey.entities, (entity) => entity.entityID === Number(paths[4]));

    const entID = !isEmpty(entity) && entity.entityID !== null && entity.entityID;

    var categoryUsers = [];

    if (delegates) {
      if (delegates.entities) {
        delegates.entities.forEach((d) => {
          if (d.entityID == entID) {
            d.categories.forEach((c) => {
              c.users.forEach((u) => {
                if (!categoryUsers.includes(u.email)) {
                  categoryUsers.push(u.email);
                }
              });
            });
          }
        });
      } else {
        delegates.users.forEach((du) => {
          categoryUsers.push(du.email);
        });
      }
    }

    return categoryUsers.length;
    //return categoryUsers;
  }

  isEntityPage() {
    const paths = this.props.history.location.pathname.split('/');

    return findIndex(this.props.survey.entities, (entity) => entity.entityID === Number(paths[4])) !== -1;
  }

  handleDrawerToggle() {
    this.setState({ open: !this.state.open });
  }

  updateDrawer() {
    if (window.innerWidth > 960 && (this.state.open || this.state.shiftHeader)) {
      this.setState({ open: false, shiftHeader: false });
    } else if (!this.state.shiftHeader && window.innerWidth <= 960) {
      this.setState({ shiftHeader: true });
    }
  }

  goBack() {
    const { history, match } = this.props;

    const paths = history.location.pathname.split('/');

    if (paths.length === 7) {
      history.replace(`/surveyLanding/${match.params.id}/entity/${paths[4]}`);
    } else {
      history.replace(`/surveyLanding/${match.params.id}/home`);
    }
  }

  getDefinitions() {
    const { classes, history, surveyType, resources, survey } = this.props;

    const paths = history.location.pathname.split('/');

    if (paths.length === 7 || (paths.length === 5 && surveyType.surveyTemplate === 'One Page')) {
      var langVal = survey.language === 'British English' ? 'English' : survey.language === '' ? 'English' : survey.language == null ? 'English' : survey.language;
      if (surveyType.name) {
        try {
          var baseURL = 'https://ha-static-content.s3.amazonaws.com/assessment-definitions/';

          if (surveyType.surveyTypeID === 8 || surveyType.name === 'OEMRAM' || surveyType.name === 'O-EMRAM') {
            return (
              <a className={classes.deflinkButton} href={baseURL + 'outpatient-defs-' + langVal + '.pdf'} target="_blank">
                {getResource(resources, survey.language, 'Label', 'Outpatient Definitions')}
              </a>
            );
          } else if (surveyType.surveyTypeID === 72) {
            return (
              <a className={classes.deflinkButtonDR} href={baseURL + 'digitalRadar-defs-German.pdf'} target="_blank">
                {getResource(resources, survey.language, 'Label', 'Definitions')}
              </a>
            );
          } else if (surveyType.surveyTypeID === 13) {
            return (
              <a className={classes.deflinkButton} href={baseURL + 'emram-defs-' + langVal + '.pdf'} target="_blank">
                {getResource(resources, survey.language, 'Label', 'EMRAM Definitions')}
              </a>
            );
          } else if (surveyType.name.includes('IS Cost Stats')) {
            return (
              <a className={classes.deflinkButton} href={baseURL + 'iscost-defs-' + langVal + '.pdf'} target="_blank">
                {getResource(resources, survey.language, 'Label', 'IS Cost Stat Definitions')}
              </a>
            );
          } else if (surveyType.name.includes('AMAM')) {
            return (
              <a className={classes.deflinkButton} href={baseURL + 'amam-defs-' + langVal + '.pdf'} target="_blank">
                {getResource(resources, survey.language, 'Label', 'AMAM Definitions')}
              </a>
            );
          } else if (surveyType.surveyTypeID === 50) {
            return (
              <a className={classes.deflinkButton} href={baseURL + 'infram-defs-' + langVal + '.pdf'} target="_blank">
                {getResource(resources, survey.language, 'Label', 'INFRAM Definitions')}
              </a>
            );
          } else if (surveyType.surveyTypeID === 84) {
            return (
              <a className={classes.deflinkButton} href={baseURL + 'infram2024-defs-' + langVal + '.pdf'} target="_blank">
                {getResource(resources, survey.language, 'Label', 'INFRAM Definitions')}
              </a>
            );
          } else if (surveyType.surveyTypeID === 57 || surveyType.surveyTypeID === 58) {
            return (
              <a className={classes.deflinkButton} href={baseURL + 'dhi-defs-' + langVal + '.pdf'} target="_blank">
                {getResource(resources, survey.language, 'Label', 'DHI Definitions')}
              </a>
            );
          } else if (surveyType.surveyTypeID === 79) {
            return (
              <a className={classes.deflinkButton} href={baseURL + 'ccmm-defs-' + langVal + '.pdf'} target="_blank">
                {getResource(resources, survey.language, 'Label', 'CCMM Definitions')}
              </a>
            );
          } else if (surveyType.name === 'C-COMM') {
            return (
              <a className={classes.deflinkButton} href={baseURL + 'ccomm-defs-' + langVal + '.pdf'} target="_blank">
                {getResource(resources, survey.language, 'Label', 'C-COMM Definitions')}
              </a>
            );
          } else {
            return '';
          }
        } catch (ex) {
          // file doesn't exist but don't break the page loading...
          return '';
        }
      }
    }
  }

  getSavingText() {
    const { history, surveyType, survey, resources } = this.props;

    const paths = history.location.pathname.split('/');

    if (paths.length === 7 || (paths.length === 5 && surveyType.surveyTemplate === 'One Page')) {
      if (surveyType.name) {
        if (surveyType.surveyTypeID === 8) {
          return getResource(resources, survey.language, 'Label', 'Your O-EMRAM progress will save when you leave this page.');
        } else if (surveyType.surveyTypeID === 13) {
          return getResource(resources, survey.language, 'Label', 'Your EMRAM progress will save when you leave this page.');
        } else if (surveyType.name.includes('IS Cost Stats')) {
          return getResource(resources, survey.language, 'Label', 'Your IS Cost Stats progress will save when you leave this page.');
        } else if (surveyType.surveyTypeID === 50) {
          return getResource(resources, survey.language, 'Label', 'Your INFRAM progress will save when you leave this page.');
        } else {
          return getResource(resources, survey.language, 'Label', 'Your progress will save when you leave this page.');
        }
      }
    }
  }

  getEntityProgress(entity) {
    const { survey, surveyType, pages, tabs, technologies } = this.props;

    let totalQuestionsAnswered = 0;
    let totalQuestions = 0;
    if (!isEmpty(survey) && !isEmpty(surveyType) && !isEmpty(pages)) {
      const surveyState = createSurveyState(survey, surveyType, entity.haEntityTypeID, pages, tabs, technologies);
      const entityPages = cloneDeep(surveyState.pages);

      const pagesWithProgress = entityPages.map((p) => {
        if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`]) {
          p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
        }

        return p;
      });

      pagesWithProgress.forEach((p) => {
        const { surveyType, tabs } = surveyState;
        const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);

        totalQuestions += pageStatus.totalQuestions;

        if (p.pageProgress && p.pageProgress.completed === true) {
          totalQuestionsAnswered += pageStatus.totalQuestions;
        } else {
          totalQuestionsAnswered += pageStatus.totalQuestionsAnswered;
        }
      });
    }

    return { totalQuestionsAnswered, totalQuestions, emptyEntity: totalQuestions == 0 };
  }

  async newGetSurvey(survey, guid) {
    const theSurvey = await this.props.getSurvey(guid);

    this.newGetDelegates(theSurvey);

    console.log('theSurvey IS:', theSurvey);
  }

  async newGetDelegates(survey) {
    var delegatesResponse = {};
    if (survey.response) {
      delegatesResponse = await this.props.getDelegates(survey.response.surveyID);
    }

    Object.keys(delegatesResponse).length === 0 ? this.setState({ delegatesResponse: delegatesResponse }) : this.setState({ delegatesResponse: delegatesResponse.response });
  }

  getRedirectURI() {
    const isGermanTenderEnv = process.env.REACT_APP_ENVIRONMENT === 'germantender';
    let surveyTypeID = this.props.survey.surveyType.surveyTypeID;

    return isGermanTenderEnv ? 'https://www.digitalradar-krankenhaus.de/' : surveyTypeID === 86 ? 'https://www.himss.org/what-we-do-solutions/digital-health-transformation':'https://www.himss.org/';
  }

  async onSubmit() {
    const { survey, resources } = this.props;
    /* Hide the dialog */
    this.setState({ showSubmitDialog: false });
    var self = this;
    /* Save the survey */
    let allowSubmit = ['Not Started', 'In Progress'];
    if(!allowSubmit.includes(survey.status)){
      this.props.handleToastMessage(getResource(resources, survey.language, 'Label', 'Failed to submit survey!'), true);
    }else{
      const response = await this.props.submitSurvey(this.props.survey.surveyGuid);
      if (response.type === SUBMIT_SURVEY_SUCCESS) {
        this.props.handleToastMessage(getResource(resources, survey.language, 'Message', 'Survey successfully submitted.'), false);
        setTimeout(function () {
          window.location.replace(self.getRedirectURI());
        }, 3000); //I think this redirects them home, and when they land home, it runs through welcome component, which defaults to step 0 which shows the screen we see.
      } else {
        this.props.handleToastMessage(getResource(resources, survey.language, 'Label', 'Failed to submit survey!'), true);
      }
    }
  }

  handleDialogClose() {
    this.setState({ openChildDialog: false });
  }

  forceSaveSurvey() {
    this.setState({ forceSaveSurvey: true });
    EventBus.dispatch('SurveySave');

    //M.DROY HACK: this is here because when you submit a survey with no changes for save, it never comes back with the surveyIsLoaded.
    this.setState({ forceSaveSurvey: false });
  }

  forceSaveSurveyAndRefreshToken() {
    this.setState({ showTimeoutDialog: false }, () => {
      this.forceSaveSurvey();
      this.renewSessionAndResetTimers();
    });
  }

  renewSessionAndResetTimers() {
    this.auth.renewSession();
    this.setAuthTimer();
  }

  setAuthTimer() {
    const userTimeout = process.env.REACT_APP_AUTH0_USER_TIMEOUT * 60 * 1000;
    const expiresAt = localStorage.getItem('expires_at');

    if (!expiresAt || userTimeout === 0) {
      return;
    }

    if (!this.state.isPrivateBrowser) {
      detectIncognito().then((result) => {
       this.setState({ isPrivateBrowser: result.isPrivate });
        if (!result.isPrivate) {
          if (window.authTimer) {
            clearTimeout(window.authTimer);
          };
    
          if (!this.state.userTokenTimeout && this.state.showTimeoutDialog) {
            const expireSession = expiresAt - (new Date().getTime() + (5 * 60 * 1000));
            window.authTimer = setTimeout(() => {
              if (window.location.href.toLowerCase().indexOf('/page/') > -1) {
                this.forceSaveSurvey();
              }
    
              this.setState({ userTokenTimeout: true });
            }, expireSession);

            return;
          }
          
          if (!this.state.showTimeoutDialog) {
            window.authTimer = setTimeout(() => {
              this.setState({ showTimeoutDialog: true }, () => {
                this.setAuthTimer()
              });
            }, userTimeout);
          }

          return;
        }
        
        this.setState({ showPrivateWindowDetected: true });
      }).catch(() => {
        this.setState({ isPrivateBrowser: true });
        this.setState({ showPrivateWindowDetected: true });
      })
    }
  }

  surveyIsLoaded() {
    this.setState({ forceSaveSurvey: false });
  }

  async saveSurvey(survey, saveType) {
    const { surveyType, technologies, match } = this.props;
    const { pages, entity, tabs } = this.state;

    delete survey.bsonId;

    if (survey.questions) {
      survey.questions.forEach((q) => {
        delete q.bsonId;
      });
    }
    if (survey.technologyQuestions) {
      survey.technologyQuestions.forEach((q) => {
        delete q.bsonId;
      });
    }

    const pageProgress = {};

    cloneDeep(pages).forEach((p) => {
      if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`] && survey.pageProgress[`${entity.entityID}-${p.pageId}`].completed) {
        p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
      } else {
        const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);

        const percentComplete = (pageStatus.totalQuestionsAnswered / pageStatus.totalQuestions) * 100;

        p.pageProgress = {
          completed: false,
          percentComplete: isNaN(percentComplete) ? 0 : Math.round(percentComplete),
        };
      }

      pageProgress[`${entity.entityID}-${p.pageId}`] = p.pageProgress;

      return p;
    });

    survey.pageProgress = { ...survey.pageProgress, ...pageProgress };

    const response = await this.props.saveSurvey(match.params.id, survey);

    this.renewSessionAndResetTimers();

    if (response.type === 'SAVE_SURVEY_SUCCESS') {
      if (saveType === 'language update') {
        if (this.state.isPrivateBrowser) {
          this.forceSaveSurvey();
        } else {
          this.forceSaveSurveyAndRefreshToken();
        }
      }
    }
  }

  logOff() {
    if (window.location.href.toLowerCase().indexOf('/page/') > -1) {
      this.setState({ showLogOutDialog: true });
    } else {
      EventBus.dispatch('LogOff');
    }
  }

  confirmLogOff() {
    EventBus.dispatch('LogOff');
  }

  checkSurveyName(surveyName) {
    var lastFiveCharacters = surveyName.substr(surveyName.length - 5);

    if (lastFiveCharacters === ' 2022' || lastFiveCharacters === ' 2024') {
      return surveyName.substr(0, surveyName.length - 5);
    } else {
      return surveyName;
    }
  }

  render() {
    const {
      auth,
      classes,
      countriesList,
      delegates,
      entityAmbulatories,
      entityFreeStandingDataCenters,
      entityHomeHealths,
      entityHospitals,
      entityInHospitalDataCenters,
      entitySubAcutes,
      entityUntetheredAmbulatories,
      history,
      isLoading,
      isApiError,
      pages,
      permissions,
      regionsList,
      survey,
      surveyType,
      tabs,
      technologies,
      technologiesList,
      vendorsList,
      regions,
      resources,
      isInternalUser,
    } = this.props;
    const { open, shiftHeader, delegatesResponse, showActivationDialog, downloading, productsList, showLogOutDialog } = this.state;
    const isGermanTenderEnv = process.env.REACT_APP_ENVIRONMENT === 'germantender';
    //console.log('surveyType.name: ', surveyType.name);

    const newDelegates = isEmpty(delegates) ? delegatesResponse : delegates;

    const surveyProgress = {
      totalQuestions: 0,
      totalQuestionsAnswered: 0,
    };
    let emptyEntities = [];
    if (survey && survey.entities) {
      survey.entities.forEach((entity) => {
        const entityProgress = this.getEntityProgress(entity);

        surveyProgress.totalQuestions += entityProgress.totalQuestions;
        surveyProgress.totalQuestionsAnswered += entityProgress.totalQuestionsAnswered;
        if (entityProgress.emptyEntity === true) {
          emptyEntities.push(entity.entityID);
        }
      });
    }

    //see if this is single survey
    let entitiesLength = 0;
    if (survey.entities) {
      entitiesLength = survey.entities.length;
    }

    /*let maintenanceMode = false;

    if (!isEmpty(this.state.maintenance)) {
        maintenanceMode = this.state.maintenance.response && true;
    }*/

    var currentlySavingCSS = isGermanTenderEnv ? classes.deflinkButtonDR : classes.deflinkButton;

    if (this.state.currentlySavingSurvey) {
      currentlySavingCSS = isGermanTenderEnv ? classes.deflinkButtonActiveDR : classes.deflinkButtonActive;
    }

    //console.log('surveyType.languageOptions is: ', surveyType.languageOptions);

    const languages = [];
    if (surveyType && surveyType.languageOptions && surveyType.languageOptions.length > 0) {
      surveyType.languageOptions.map(function (language) {
        languages.push({ label: language, value: language });
      });
    } else {
      languages.push({ label: 'English', value: 'English' });
    }

    var surveyLanguage = '';

    if (survey && survey.entities) {
      surveyLanguage = survey.language === null || survey.language === '' || survey.language === undefined ? 'English' : survey.language;
    }

    var showSubmitButton = true;
    if (process.env.REACT_APP_DOD) {
      showSubmitButton = false;
    }
    const rootClasses = {
      digitalRadar: isGermanTenderEnv,
    };

    const mobileHeaderLogo = isGermanTenderEnv ? DRLogo : Logo;

    return (
        <ValidatorForm
          id="theForm"
          className={this.props.classes.form}
          onSubmit={this.onSubmit}
          onError={(errors) => {
            console.log('form error', errors);
            errors.forEach((e) => console.log('error', e.getErrorMessage()));
          }}
          debounceTime={500}
        >
          <div className={classNames(classes.root, rootClasses)}>
            {this.state.maintenanceEnabled === true && (
              <div className={classes.maintBanner} onClick={this.handleCloseMaintenance}>
                <span className={classes.maintSpan}>{this.getMaintenanceBanner(this.state.maintenanceResponse)}</span>
                <span className={classes.maintClose}>
                  <i>&nbsp;&nbsp;({getResource(resources, survey.language, 'Label', 'click to dismiss')})</i>
                </span>
              </div>
            )}
            <Hidden mdUp implementation="css">
              <AppBar
                position="fixed"
                className={classNames(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar className={classes.toolBar} disableGutters={!open}>
                  
                  <img src={mobileHeaderLogo} className="headerLogo" alt="" />
                </Toolbar>
              </AppBar>
            </Hidden>
            <div className={classes.drawer}>
              <Drawer
                variant="persistent"
                anchor="left"
                open={open}
                classes={
                  this.state.maintenanceEnabled === true
                    ? {
                        paper: classes.drawerPaperPadded,
                      }
                    : {
                        paper: classes.drawerPaper,
                      }
                }
              >
                {this.DrawerContent()}
              </Drawer>
              <Hidden smDown implementation="css">
                <Drawer
                  classes={
                    this.state.maintenanceEnabled === true
                      ? {
                          paper: classes.drawerPaperPadded,
                        }
                      : {
                          paper: classes.drawerPaper,
                        }
                  }
                  variant="permanent"
                  open
                >
                  {this.DrawerContent()}
                </Drawer>
              </Hidden>
            </div>
            <div
              //className={classNames(classes.content, { [classes.contentShift]: open, })}
              className="content"
              id="custom-form-wrapper"
            >
              {isEmpty(survey) || downloading ? (
                <LoadingOverlay />
              ) : (
                <Fragment>
                  <div
                    className="headerWrapper"
                    /*was:
                    className={classNames(classes.headerWrapper, {
                    [classes.headerShift]: shiftHeader,
                  })}*/
                    style={
                      this.state.maintenanceEnabled === true
                        ? {
                            marginTop: '41px',
                          }
                        : {
                            marginTop: '0px',
                          }
                    }
                  >
                    <Grid container justifyContent="space-between" className={'progressBarGridWrapper'}>
                      <Grid item className={classNames(classes.progressBarGrid, 'mobiprogressbargrid')}>
                        <Typography variant="h5" className={classNames(classes.orgName, 'mobiorgname')}>
                          {this.checkSurveyName(survey.surveyName)}
                        </Typography>
                        {(surveyType.scoringType === 'EMRAM' ||
                          surveyType.scoringType === 'AMAM' ||
                          surveyType.scoringType === 'O-EMRAM' ||
                          surveyType.scoringType === 'INFRAM' ||
                          surveyType.scoringType === 'DHIShort' ||
                          surveyType.scoringType === 'DHI' ||
                          surveyType.scoringType === 'CISOM' ||
                          surveyType.scoringType === 'DIAM' ||
                          surveyType.surveyTypeID === 6 ||
                          surveyType.surveyTypeID === 62 ||
                          surveyType.surveyTypeID === 70 ||
                          surveyType.surveyTypeID === 72 ||
                          surveyType.surveyTypeID === 75 ||
                          surveyType.surveyTypeID === 76 ||
                          surveyType.surveyTypeID === 77 ||
                          surveyType.surveyTypeID === 79 ||
                          surveyType.surveyTypeID === 84) && ( //DW right now we are only translating certain types
                          <Fragment>
                            <img className={'gear'} onClick={this.handleOpenActivation} src={isGermanTenderEnv ? GearDR : Gear} alt="Settings" />
                          </Fragment>
                        )}
                        <Fragment></Fragment>
                        <div className={classes.progressContainer}>
                          {/* <Typography variant="h5">Survey Progress</Typography> */}
                          <div className={classes.progressBarWrapper}>
                            <LinearProgress
                              value={surveyProgress.totalQuestions > 0 ? Math.floor((surveyProgress.totalQuestionsAnswered / surveyProgress.totalQuestions) * 100) : 0}
                              variant="determinate"
                              className={classes.progressBar}
                              classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: isGermanTenderEnv ? classes.barColorPrimaryDR : classes.barColorPrimary }}
                            />
                            <Typography variant="subtitle1" className={isGermanTenderEnv ? classes.progressBarPercentageDR : classes.progressBarPercentage}>
                              {surveyProgress.totalQuestions > 0 ? Math.floor((surveyProgress.totalQuestionsAnswered / surveyProgress.totalQuestions) * 100) : 0} %
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid item sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.breadcrumbsWrapper}>
                              <div className={classes.breadcrumbLink}>
                                {history.location.pathname.includes('entity') && !history.location.pathname.includes('page') && (
                                  <span className={classes.breadcrumbText} style={{ cursor: 'pointer' }} onClick={() => this.goBack()}>
                                    {`< ${getResource(resources, survey.language, 'Label', 'Home')} | ${this.getEntityName()}`}
                                  </span>
                                )}
                                {history.location.pathname.includes('page') && (
                                  <span className={classes.breadcrumbText} style={{ cursor: 'pointer' }} onClick={() => this.goBack()}>
                                    {`< ${getResource(resources, survey.language, 'Label', 'Back')} | ${this.getEntityName()} | ${this.getPageName()}`}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className={classes.saveText} variant="caption">
                              {/*{this.getSavingText()}*/}
                              {history.location.pathname.includes('page') && (
                                <div className={classes.breadcrumbLink}>
                                  {' '}
                                  <a className={currentlySavingCSS} onClick={this.state.isPrivateBrowser ? this.forceSaveSurvey : this.forceSaveSurveyAndRefreshToken}>
                                    {this.state.surveyText === 'Save Survey' ? getResource(resources, survey.language, 'Label', 'Save Survey') : this.state.surveyText}
                                  </a>{' '}
                                </div>
                              )}

                              <div className={classes.breadcrumbLink}>{this.getDefinitions()}</div>

                              {entitiesLength == 1 &&
                                showSubmitButton &&
                                !history.location.pathname.includes('page') &&
                                history.location.pathname.includes('entity') &&
                                hasPermission(CREATE_DELEGATES, permissions) && ( //if single survey, we are on sections page, and admin user, show submit button
                                  <div className={classes.deflink}>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      style={{ right: '19px' }}
                                      onClick={() => {
                                        this.setState({ showSubmitDialog: true });
                                      }}
                                    >
                                      {getResource(resources, survey.language, 'Label', 'Submit Survey')}
                                    </Button>
                                  </div>
                                )}
                              <Confirm
                                title={getResource(resources, survey.language, 'Message', 'Submit Complete Survey?')}
                                onClose={() => this.setState({ showSubmitDialog: false })}
                                onConfirm={this.onSubmit}
                                contentText={
                                  process.env.REACT_APP_ENVIRONMENT !== "germantender" ?
                                    getResource(resources, survey.language, 'msgSubmit', '') :
                                    getResource(resources, survey.language, 'msgSubmit', '') +
                                    "<br /><br />Weiternutzung der übermittelten Daten: Ausgewählte im Rahmen des DigitalRadar Krankenhaus erhobene institutionelle Daten werden auch für weitere Forschende und/oder Forschungszwecke zur Verfügung gestellt. Einzelheiten dazu entnehmen Sie bitte unseren <a href='https://www.digitalradar-krankenhaus.de/nutzungsbedingungen/' target='_blank'>Nutzungsbedingungen</a> sowie der <a href='https://www.digitalradar-krankenhaus.de/nutzungsbedingungen/datenbereitstellung' target='_blank'>Beschreibung des Forschungsdatensatzes</a>.<br /><br />" +
                                    "Wenn Ihre Institution einer Nutzung der Daten im beschriebenen Umfang nicht zustimmt, besteht die Möglichkeit dieser zu widersprechen. Senden Sie hierfür bis spätestens 30.10.2024 eine formlose E-Mail an <a href='mailto:datenschutz@digitalradar-krankenhaus.de' target='_blank'>datenschutz@digitalradar-krankenhaus.de</a>. Zur eindeutigen Zuordnung müssen in der E-Mail folgende Informationen enthalten sein: Krankenhausname, IK-Nummer und Standortnummer."
                                }
                                open={this.state.showSubmitDialog}
                                confirmText={getResource(resources, survey.language, 'Label', 'Confirm')}
                                cancelText={getResource(resources, survey.language, 'Label', 'Cancel')}
                              />
                              {history.location.pathname.includes('entity') && !history.location.pathname.includes('page') && this.props.surveyType.name != "Satisfaction Survey" && (
                                <div className={classes.deflink}>
                                  <Button
                                    color="primary"
                                    variant={isEmpty(newDelegates.users) || newDelegates.users.length === 0 ? 'contained' : 'outlined'}
                                    /*style={{ padding: '.5rem 1.7rem', right: '19px' }}*/
                                    onClick={() => {
                                      this.setState({ openChildDialog: true });
                                    }}
                                  >
                                    {!isEmpty(newDelegates.users)
                                      ? `${newDelegates.users.length + this.getEntityDelegatesCount(newDelegates) + ' ' + getResource(resources, survey.language, 'Label', 'DELEGATE(S)')}`
                                      : getResource(resources, survey.language, 'Label', 'DELEGATE')}
                                  </Button>
                                </div>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <div id="innerContentWrapper" className="sassStyling">
                    <Switch>
                      <Route
                        path="/surveyLanding/:id/home"
                        render={(props) => (
                          <React.Fragment>
                            {!this.state.forceSaveSurvey ? (
                              <SurveyLandingHome
                                {...props}
                                auth={auth}
                                permissions={permissions}
                                delegates={newDelegates}
                                pages={pages}
                                survey={survey}
                                surveyType={surveyType}
                                tabs={tabs}
                                technologies={technologies}
                                resources={resources}
                                emptyEntities={emptyEntities}
                                isApiError={isApiError}
                              />
                            ) : (
                              <Hidden>{getResource(resources, survey.language, 'Message', 'Saving')}...</Hidden>
                            )}
                          </React.Fragment>
                        )}
                      />
                      <Route
                        exact
                        path="/surveyLanding/:id/entity/:entityId"
                        render={(props) => (
                          <React.Fragment>
                            {!this.state.forceSaveSurvey ? (
                              <React.Fragment>
                                <Entity
                                  {...props}
                                  isApiError={isApiError}
                                  key={Math.random()}
                                  answers={survey.answers}
                                  auth={auth}
                                  countriesList={countriesList}
                                  delegates={newDelegates}
                                  pages={pages}
                                  permissions={permissions}
                                  productsList={productsList}
                                  regionsList={regionsList}
                                  survey={survey}
                                  surveyType={surveyType}
                                  tabs={tabs}
                                  technologies={technologies}
                                  technologiesList={technologies}
                                  vendorsList={vendorsList}
                                  regions={regions}
                                  openChildDialog={this.state.openChildDialog}
                                  handleDialogClose={this.handleDialogClose}
                                  resources={resources}
                                  isInternalUser={isInternalUser}
                                />
                                {entitiesLength == 1 &&
                                  showSubmitButton &&
                                  !history.location.pathname.includes('page') &&
                                  history.location.pathname.includes('entity') &&
                                  surveyType.surveyTemplate === 'One Page' &&
                                  hasPermission(CREATE_DELEGATES, permissions) && ( //if single survey, we are on sections page, and admin user, show submit button
                                    <div className={classes.deflink}>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        style={{ right: '19px' }}
                                        onClick={() => {
                                          this.setState({ showSubmitDialog: true });
                                        }}
                                      >
                                        {getResource(resources, survey.language, 'Label', 'Submit Survey')}
                                      </Button>
                                    </div>
                                  )}
                              </React.Fragment>
                            ) : (
                              <Hidden>{getResource(resources, survey.language, 'Message', 'Saving')}...</Hidden>
                            )}
                          </React.Fragment>
                        )}
                      />
                      <Route
                        exact
                        path="/surveyLanding/:id/entity/:entityId/page/:pageId"
                        render={(props) => (
                          <React.Fragment>
                            {!this.state.forceSaveSurvey ? (
                              <SurveyPage
                                {...props}
                                answers={survey.answers}
                                countriesList={countriesList}
                                isApiError={isApiError}
                                pages={pages}
                                productsList={productsList}
                                regionsList={regionsList}
                                setTechnologyName={(techName) => this.setState({ techName })}
                                survey={survey}
                                surveyType={surveyType}
                                tabs={tabs}
                                delegates={newDelegates}
                                auth={auth}
                                technologies={technologies}
                                technologiesList={technologiesList}
                                vendorsList={vendorsList}
                                regions={regions}
                                surveyIsLoaded={this.surveyIsLoaded}
                                activeTab={this.state.activeTab}
                                onChangeTab={this.onChangeTab}
                                resources={resources}
                                handleToastMessage={this.props.handleToastMessage}
                              />
                            ) : (
                              <Hidden>{getResource(resources, survey.language, 'Message', 'Saving')}...</Hidden>
                            )}
                          </React.Fragment>
                        )}
                      />
                    </Switch>
                  </div>
                  
                </Fragment>
              )}
            </div>
            <Settings
              contentText={getResource(resources, survey.language, 'Label', 'Survey Settings')}
              title={getResource(resources, survey.language, 'Label', 'Survey Settings')}
              updateZoom={this.updateZoomLevel}
              setZoom={this.setZoomLevel}
              zoomLevel={this.state.zoomLevel}
              onClose={this.handleCloseActivation}
              updateLanguage={this.updateLanguage}
              setLanguage={this.setLanguage}
              newLang={this.state.newLang}
              updateExport={this.updateExportOption}
              setExport={this.setExportOption}
              exportOption={this.state.exportOption}
              open={showActivationDialog}
              label="Comments"
              surveyTypeVal={surveyType}
              handleLogOff={this.logOff}
              isMulti
              updateEntitiesToExport={this.updateEntitiesToExport}
              entitiesToExport={this.state.entitiesToExport}
            />
            <Confirm
              title={getResource(resources, survey.language, 'Label', 'Required fields')}
              contentText={getResource(resources, survey.language, 'Label', 'The following fields are required:')}
              open={this.state.requiredFieldError}
              buttons="confirm-only"
              onConfirm={() => this.setState({ requiredFieldError: false })}
            />
            <LogOff
              title={getResource(resources, survey.language, 'Label', 'Log Off')}
              onClose={() => this.setState({ showLogOutDialog: false })}
              onConfirm={this.confirmLogOff}
              onSave={this.forceSaveSurvey}
              contentText={getResource(resources, survey.language, 'Message', 'Do you want to save your assessment before exiting?')}
              open={this.state.showLogOutDialog}
              confirmText={getResource(resources, survey.language, 'Label', 'No')}
              cancelText={getResource(resources, survey.language, 'Label', 'Cancel')}
              saveText={getResource(resources, survey.language, 'Label', 'Yes')}
              savedText={getResource(resources, survey.language, 'Message', 'SAVED')}
              logOutText={getResource(resources, survey.language, 'Label', 'Log Off')}
            />
            <AuthTimeout 
              open={this.state.showTimeoutDialog && window.location.href.toLowerCase().indexOf('/page/') > -1}
              contentText={this.state.userTokenTimeout ?
                getResource(resources, survey.language, 'Message', "You have been automatically logged out due to inactivity. Your data has been saved.") :
                getResource(resources, survey.language, 'Message', "Your session is about to expire which will automatically log you out. Click 'Continue' to save and stay logged in.")
              }
              logoffText={getResource(resources, survey.language, 'Label', 'Log Off')}
              continueText={getResource(resources, survey.language, 'Label', 'Continue')}
              onContinue={this.state.userTokenTimeout ? this.confirmLogOff : this.forceSaveSurveyAndRefreshToken}
              onLogoff={this.logOff}
              hideLogoffButton={this.state.userTokenTimeout}
            />
            <Acknowledge
              open={this.state.showPrivateWindowDetected && !this.state.privateWindowAcknowledged}
              contentText={getResource(resources, survey.language, 'Message', "We have detected you might be using a private browsing window. By using this feature, the assessment auto-save features may not function properly. Please be aware, data might get lost if you do not manually save your data frequently.")}
              closeText={getResource(resources, survey.language, 'Label', 'Ok')}
              onClose={() => {
                this.setState({ showPrivateWindowDetected: false });
                sessionStorage.setItem('private_window_acknowledged', true);
              }}
            />
            {isGermanTenderEnv && (
              <Confirm
                title="EINWILLIGUNG NUTZUNGSBEDINGUNGEN"
                contentText="Um mit der Datenerhebung fortzufahren, bestätigen Sie bitte die <a href='https://www.digitalradar-krankenhaus.de/nutzungsbedingungen/' target='_blank'>Allgemeinen Nutzungsbedingungen</a> der DigitalRadar-Krankenhaus Plattform und Dashboard."
                cancelText="Ablehnen"
                confirmText="Bestätigen"
                open={this.state.touOpen}
                onClose={() => {
                  let msg = getResource(resources, survey.language, 'msgDeclineTou', '');
                  var r = window.confirm(msg);
                  if (r == true) {
                    window.location.href = 'https://www.digitalradar-krankenhaus.de/';
                  }
                }}
                onConfirm={(event) => {
                  localStorage.setItem('tou_hidden', true);
                  this.setState({ touOpen: false });
                }}
              />
            )}
    
          </div> 
          
          <CookieConsent />  
        
      </ValidatorForm>      
    );
  }
}

const styles = ({ breakpoints, transitions, spacing, mixins }) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  cc: {
    zIndex: "999999 !imporant",
    padding: 0
  },
  panel: {
    alignItems: 'center',
    backgroundImage: `url(${LeftPanel})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '24rem',
    padding: '1rem',
    paddingBottom: '2rem',
    flexGrow: '1',
  },
  drpanel: {
    backgroundImage: `url(${LeftPanelDR})`,
  },
  logo: {
    marginBottom: '2rem',
    maxWidth: '17.5rem',
    width: '100%',
  },
  breadcrumbText: {
    color: '#888784',
  },
  breadcrumbsWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  submitWrapper: {
    alignItems: 'right',
    display: 'flex',
    flexDirection: 'row',
  },
  deflink: {
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'right',
    fontSize: '1rem',
    fontWeight: 700,
    borderRadius: '1rem',
    textDecoration: 'none',
  },
  deflinkButtonDR: {
    border: '1px solid rgba(26, 37, 171, 0.5)',
    padding: '5px 16px',
    fontSize: '0.75rem',
    lineHeight: '1.75',
    fontFamily: "'Century Gothic', CenturyGothic, AppleGothic, sans-serif",
    fontWeight: 700,
    borderRadius: '20px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    textDecoration: 'none',
    background: 'rgb(0,85,150)',
    color: '#fff',
  },
  deflinkButtonActiveDR: {
    border: '1px solid rgba(26, 37, 171, 0.5)',
    padding: '5px 16px',
    fontSize: '0.75rem',
    lineHeight: '1.75',
    fontFamily: "'Century Gothic', CenturyGothic, AppleGothic, sans-serif",
    fontWeight: 700,
    borderRadius: '20px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    textDecoration: 'none',
    background: '#fff',
    color: 'rgb(0,85,150)',
    width: '78px',
    textAlign: 'center',
  },
  deflinkButton: {
    border: '1px solid rgba(26, 37, 171, 0.5)',
    padding: '5px 16px',
    fontSize: '0.75rem',
    lineHeight: '1.75',
    fontFamily: '"Verlag Bold", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    borderRadius: '20px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    textDecoration: 'none',
    background: '#1a25ab',
    color: '#fff',
  },
  deflinkButtonActive: {
    border: '1px solid rgba(26, 37, 171, 0.5)',
    padding: '5px 16px',
    fontSize: '0.75rem',
    lineHeight: '1.75',
    fontFamily: '"Verlag Bold", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    borderRadius: '20px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    textDecoration: 'none',
    background: '#fff',
    color: '#1a25ab',
    width: '78px',
    textAlign: 'center',
  },
  breadcrumbLink: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveText: {
    color: '#888784',
    textAlign: 'right',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '3.75rem',
    position: 'fixed',
    padding: '24px',
    left: '407px',
    width: 'calc(100% - 475px)',
    top: 0,
    background: 'white',
    zIndex: 2,
  },
  headerShift: {
    transition: transitions.create('margin', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    marginTop: '4rem',
  },
  supportContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    width: '100%',
    padding: '2rem 0 5rem 0',
  },
  supportContainerPadded: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '2rem 0 5rem 0',
    bottom: '50px',
  },
  faqButton: {
    border: '1px solid white !important',
    borderRadius: '2rem',
    color: 'white !important',
    fontSize: '1rem',
    height: '2.5rem',
    minWidth: '11rem',
    width: '55%',
    marginBottom: '1em',
    '&:hover': {
      backgroundColor: '#4dc0e8',
    },
  },
  emailButton: {
    border: '1px solid white !important',
    borderRadius: '2rem !important',
    color: 'white !important',
    fontSize: '0.8rem !important',
    height: '2.5rem',
    minWidth: '11rem !important',
    textAlign: 'center',
    lineHeight: '17px !important',
    width: 'auto',
    '&:hover': {
      backgroundColor: '#4dc0e8 !important',
    },
  },
  needHelp: {
    color: 'white',
    marginBottom: '.5rem',
  },
  drawer: {
    [breakpoints.up('md')]: {
      width: '25%',
      minWidth: '17rem',
      maxWidth: '24rem',
      flexShrink: 0,
    },
    [breakpoints.down('md')]: {
      display: 'none'
    },
  },
  drawerPaper: {
    width: '25%',
    minWidth: '17rem',
    maxWidth: '24rem',
  },
  drawerPaperPadded: {
    width: '25%',
    minWidth: '17rem',
    maxWidth: '24rem',
    marginTop: '41px',
  },
  content: {
    flexGrow: 1,
    padding: spacing(3),
    marginTop: '150px',
  },
  contentShift: {
    transition: transitions.create('margin', {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
    width: 'calc(100% - 20rem)',
    marginLeft: '17rem',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  appBar: {
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: 'calc(100% - 16rem)',
    marginLeft: '17rem',
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLogo: {
    maxWidth: '8.5rem',
    marginRight: '2rem',
  },
  progressBar: {
    height: '2rem !important',
    borderRadius: '1rem !important',
    overflow: 'hidden !important',
    flexGrow: 1,
    //marginRight: '1rem',
    background: '#eaeaea !important',
  },
  progressBarWrapper: {
    display: 'flex !important',
    alignItems: 'center !important',
    margin: '.5rem 0 !important',
    position: 'relative !important',
  },
  progressBarGrid: {
    width: '100% !important',
  },
  progressBarPercentageDR: {
    position: 'absolute !important',
    right: '0 !important',
    fontWeight: '700 !important',
    marginRight: '.5rem !important',
    color: 'rgb(0,85,150) !important',
    fontSize: '1.1rem !important',
    fontStyle: 'italic !important',
  },
  progressBarPercentage: {
    position: 'absolute !important',
    right: '0 !important',
    fontWeight: '700 !important',
    marginRight: '.5rem !important',
    color: '#1a25ab !important',
    fontSize: '1.1rem !important',
    fontStyle: 'italic !important',
  },
  progressContainer: {
    width: '100% !important',
    marginTop: '20px !important',
    //maxWidth: '50rem'
  },
  colorPrimary: {
    backgroundColor: '#eaeaea',
  },
  barColorPrimaryDR: {
    backgroundColor: 'rgb(41, 171, 227) !important',
  },
  barColorPrimary: {
    backgroundColor: '#4dc0e8 !important',
  },
  select: {
    width: 140,
    color: '#1a25ab !important',
  },
  formControl: {
    width: '140px',
    right: '23px',
    float: 'right',
    display: 'inline',
    color: '#1a25ab !important',
  },
  orgName: {
    maxWidth: '600px',
    display: 'inline',
  },
  langSelect: {
    color: '#1a25ab  !important',
    fontSize: '1.2rem',
    minHeight: '0.1875em',
    textAlign: 'center',
    maxHeight: '30px',
    top: '-5px',
    borderTop: '2px solid #1a25ab !important',
    borderLeft: '2px solid #1a25ab !important',
    borderRight: '2px solid #1a25ab !important',
    borderBottom: '2px solid #1a25ab !important',
    borderRadius: '20px',
  },
  dropdownStyle: {
    top: '54px !important',
  },
  beta: {
    top: '-3px',
    color: '#1a25ab',
    display: 'inline',
    right: '54px',
    position: 'absolute',
    height: '100px',
    maxWidth: '100%',
    fontSize: '1rem',
  },
  hiddenLink: {
    display: 'none',
  },
  maintBanner: {
    width: '100%',
    height: '41px',
    background: '#EAEAEA',
    position: 'fixed',
    cursor: 'pointer',
    zIndex: '9999',
    textAlign: 'center',
  },
  maintSpan: {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    lineHeight: '2.1',
  },
  maintClose: {
    color: 'red',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginTop: '3px',
    marginRight: '9px',
  },
});

const mapStateToProps = (state, props) => {
  console.log(
    'survey landing mapStateToProps',
    state.surveys.get('isApiError'),
    state.pages.get('isApiError'),
    state.tabs.get('isApiError'),
    state.questions.get('isApiError'),
    state.organizations.get('isApiError'),
    state.vendors.get('isApiError'),
    state.products.get('isApiError'),
    state.technologies.get('isApiError'),
    state.countries.get('isApiError')
  );
  return {
    isApiError:
      state.surveys.get('isApiError') ||
      state.pages.get('isApiError') ||
      state.tabs.get('isApiError') ||
      state.questions.get('isApiError') ||
      state.organizations.get('isApiError') ||
      state.vendors.get('isApiError') ||
      state.products.get('isApiError') ||
      state.technologies.get('isApiError') ||
      state.countries.get('isApiError'),
    isLoading:
      state.surveys.get('isLoading') ||
      state.surveys.get('isSaving') ||
      state.pages.get('isLoading') ||
      state.tabs.get('isLoading') ||
      state.questions.get('isLoading') ||
      state.organizations.get('isLoading') ||
      state.technologies.get('isLoading') ||
      state.countries.get('isLoading'),
    auth: state.auth.get('tokenInfo'),
    countriesList: state.countries.get('countriesList'),
    delegates: state.delegates.get('delegates'),
    isInternalUser: state.delegates.get('isInternalUser'),
    entityTypes: state.entities.get('entityTypes'),
    organizationTypes: state.organizations.get('organizationTypes'),
    pages: state.pages.get('pages'),
    permissions: state.auth.get('permissions'),
    productsList: state.products.get('productsList'),
    regionsList: state.countries.get('regionsList'),
    survey: state.surveys.get('survey'),
    surveyType: state.surveys.get('survey').surveyType,
    surveys: state.surveys.get('mySurveys'),
    tabs: state.tabs.get('tabs'),
    technologies: state.technologies.get('technologies'),
    technologiesList: state.technologies.get('technologiesList'),
    vendorsList: state.vendors.get('vendorsList'),
    regions: state.countries.get('regions'),
    resources: state.resources.get('resources'),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getCountriesList,
    getDelegates,
    getUserInfo,
    getEntityTypes,
    getOrganizationTypes,
    getPages,
    getProductsList,
    getRegionsList,
    getSurvey,
    getTabs,
    getTechnologies,
    getTechnologiesList,
    getUserSurveys,
    getVendorsList,
    saveSurvey,
    saveSurveyChanges,
    submitSurvey,
    handleToastMessage,
    getSettings,
    getMaintenance,
    getRegions,
    getResources,
    downLoadSurveyPDF,
    downLoadSurveyType,
  })(withRouter(SurveyLandingContainer))
);
